import { faHighlighter } from "@fortawesome/free-solid-svg-icons/faHighlighter";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Button, Flex, Menu } from "@mantine/core";

import { type Nullable } from "@/types/utils";

interface HighlightMenuProps {
  activeIndicatorColor?: string;
  onChange(value: Nullable<string>): void;
  withLabel?: boolean;
}

// The actual color values have changed over time. Rather than migrate them all
// in the DB anytime there is a change we can just keep a map of generic names
// to current color codes.
const HIGHLIGHT_COLOR_MAP = {
  blue: "#89d8f1",
  green: "#18bfa0",
  orange: "#d97601",
  purple: "#784dd5",
  red: "#bb0100",
  yellow: "#f6d03e",
} as const;

type Colors = Nullable<keyof typeof HIGHLIGHT_COLOR_MAP>;

const getCodeForHighlightColor = (color: Colors) =>
  color ? HIGHLIGHT_COLOR_MAP[color] : "transparent";

const HIGHLIGHT_OPTIONS: {
  value: Colors;
  label: string;
}[] = [
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: null, label: "None" },
] as const;

export const HighlightMenu = ({
  activeIndicatorColor,
  onChange,
  withLabel = false,
}: HighlightMenuProps) => {
  return (
    <Menu>
      <Menu.Target>
        <div className="flex items-center gap-2 cursor-pointer">
          {withLabel ? (
            <Button
              color="#784DD6"
              rightSection={
                <Flex>
                  <FontAwesomeIcon icon={faHighlighter} />
                  <FontAwesomeIcon icon={faCaretDown} />
                </Flex>
              }
              variant="transparent"
            >
              HIGHLIGHT SELECTED
            </Button>
          ) : (
            <ActionIcon
              variant="transparent"
              color="#784DD6"
              className="flex items-center"
            >
              <FontAwesomeIcon icon={faHighlighter} />
            </ActionIcon>
          )}
          {activeIndicatorColor && (
            <div
              className="w-4 h-4 rounded-full"
              style={{
                backgroundColor: activeIndicatorColor,
              }}
            />
          )}
        </div>
      </Menu.Target>
      <Menu.Dropdown className="z-50">
        {HIGHLIGHT_OPTIONS.map(({ label, value }) => (
          <Menu.Item
            key={value}
            onClick={(e) => {
              e.stopPropagation();

              onChange(value);
            }}
          >
            <div className="flex gap-2 items-center">
              <div
                className="w-4 h-4 rounded-full"
                style={{
                  backgroundColor: getCodeForHighlightColor(value),
                }}
              ></div>
              <span>{label}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
